import axios from 'axios';

const baseURL = 'https://fhir-open.sandboxcerner.com/dstu2/0b8a0111-e8e6-4c26-a91c-5069cbc6b1ca'

const headers = {'Accept': ' application/json+fhir'};

const client = axios.create({
    baseURL,
    headers,
});

export default client;