import find from 'lodash/find';
import client from './client';
import Resource from './Resource';
import Collection from './Collection';

export default class Patient extends Resource {

    static async search(params){
        params._count = 100;
        const res = await client.get('/Patient', {params});
        const {data} = res;
        
        const collection = new Collection(data, Patient);
        return collection;
    }

    get fullName(){
        const name = find(this.resource.name, {use: "official"});
        return name.text || this.resource.name[0].text;
    }

    get gender(){
        return this.resource.gender;
    }

    get dateOfBirth(){
        return this.resource.birthDate;
    }
}