import client from './client';
import Resource from './Resource';
import Collection from './Collection';
import get from 'lodash/get';

export default class Condition extends Resource {

    static async findForPatient(patientId){
        const params = {patient: patientId};
        const res = await client.get('/Condition', {params});
        const {data} = res;
        
        const collection = new Collection(data, Condition);
        return collection;
    }

    get isActive(){
        return this.resource.clinicalStatus === 'active';
    }

    get text(){
        return this.resource.code.text;
    }

    get description(){
        return get(this, 'resource.code.coding[0].display', this.text);
    }

    get dateRecorded(){
        return this.resource.dateRecorded;
    }
}