import { put, call, takeLeading } from 'redux-saga/effects';
import * as patientActions from '../actions/patients';
import { Patient } from '../../services/api/index';

function* search({params}){
    try {
        yield put(patientActions.search.request(params));
        const patients = yield call(Patient.search, params);
        yield put(patientActions.search.success(params, patients));
    } catch(error){
        yield put(patientActions.search.failure(error, params));
    }
}

function* loadMore({collection}){
    try {
        if(collection.hasNext) {
            yield put(patientActions.loadMore.request(collection));
            const newCollection = yield call(collection.next.bind(collection));
            yield put(patientActions.loadMore.success(collection, newCollection));
        }
    } catch(error){
        yield put(patientActions.loadMore.failure(error, collection));
    }
}

export function* watchSearchPatients(){
    yield takeLeading(patientActions.SEARCH_PATIENTS_CLICKED, search)
}

export function* watchLoadMorePatients(){
    yield takeLeading(patientActions.LOAD_MORE_PATIENTS_CLICKED, loadMore)
}
