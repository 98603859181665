
export default class Resource {

    constructor(resource){
        this.resource = resource.resource;
    }

    get id(){
        return this.resource.id;
    }
}