import merge from 'lodash/merge';

import * as patientConditionsActions from '../actions/conditions';

const initialState = {
    isLoading: false,
    collection: {},
    error: null,
};

export const patientsReducer = (state = initialState, action) => {
    switch (action.type) {
        case patientConditionsActions.SEARCH_PATIENT_CONDITIONS.REQUEST:
            return merge({}, state, {
                isLoading: true,
                error: null
            });

        case patientConditionsActions.SEARCH_PATIENT_CONDITIONS.SUCCESS:
            const {patientId, conditions} = action;
            console.log('conditions in reducer',conditions)

            return merge({}, state, {
                isLoading: false,
                collection: merge({}, state.collection, {[`${patientId}`]: conditions.entries}),
                error: null,
            });
        
        case patientConditionsActions.SEARCH_PATIENT_CONDITIONS.FAILURE:
            return merge({}, state, {
                isLoading: false,
                error: "Something went wrong",
            });

        default:
            return state;
    }
}

export default patientsReducer