import React, {Component} from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';

export default class PatientsSearch extends Component {
    
    static propTypes = {
        searchPatients: propTypes.func,
        onSearch: propTypes.func,
    }

    static defaultProps = {
        searchPatients: noop,
        onSearch: noop,
    }

    constructor(props){
        super(props);

        this.state = {
            name: '',
        }

        this.onInputChange = this.onInputChange.bind(this);
        this.search = this.search.bind(this);
    }

    onInputChange(event){
        const {name, value} = event.target;
        this.setState({[name]: value})
    }

    search(e){
        e.preventDefault();
        this.props.searchPatients(this.state)
        this.props.onSearch(this.state);
    }

    render(){
        return (
            <div className="PatientSearch">
                <Form layout="inline" onSubmit={this.search}>
                    <Form.Item>
                        <Input type="text" 
                                name="name"
                                placeholder="Patient name"
                                value={this.state.name} 
                                onChange={this.onInputChange} 
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" >Search</Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}