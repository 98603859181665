import React, {Component} from 'react';
import propTypes from 'prop-types';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import uniqWith from 'lodash/uniqWith';

import ConditionsList from '../ConditionsList';
import Section from '../Layout/Section';
import AsyncDisplays from '../../components/AsyncDisplays';

export default class PatientInfo extends Component {

    static propTypes = {
        patientId: propTypes.string.isRequired,
        patients: propTypes.object,
        patientConditions: propTypes.object,
    }
    
    static defaultProps = {
        patient: null
    }

    findPatient(props){
        const {patientId, patients} = props;
        return find(patients.collection.entries, {id: patientId});
    }

    prepareConditions(conditions){
        const sorted = sortBy(conditions, 'text');
        const sortedUnique = uniqWith(sorted, (a, b) => {

            if(a.text === b.text) {
                return true;
            } else {
                return false
            }
        });

        return sortedUnique;
    }

    render(){
        const { patientConditions, patients, patientId } = this.props;
        const patient = this.findPatient(this.props);

        if(!patient) return <div>No Patient selected</div>;

        const conditions = sortBy(this.prepareConditions(patientConditions.collection[patientId]), 'dateRecorded');

        return (
            <div className="PatientInfo">
                <Section>
                    <h2>{patient.fullName}</h2>
                    <div>
                        <div>ID: {patient.id}</div>
                        <div>Gender: {patient.gender}</div>
                        <div>Date Of Birth: {patient.dateOfBirth}</div>
                    </div>
                </Section>

                <Section>
                    <h3>Conditions</h3>
                    <AsyncDisplays 
                        isLoading={patientConditions.isLoading} 
                        error={patientConditions.error}
                    >
                        { conditions && conditions.length ? (
                            <ConditionsList 
                                conditions={conditions} 
                                key={patient.id} 
                            />
                        ) : <div>No records exist for this patient.</div> }
                    </AsyncDisplays>
                </Section>
            </div>
        )
    }
}
