import {action, createRequestTypes} from './createAction';

export const SEARCH_PATIENT_CONDITIONS_CLICKED = 'SEARCH_PATIENT_CONDITIONS_CLICKED';

export const searchPatientConditions = patientId => action(SEARCH_PATIENT_CONDITIONS_CLICKED, {patientId})

export const SEARCH_PATIENT_CONDITIONS = createRequestTypes('SEARCH_PATIENT_CONDITIONS');
export const search = {
    request: (patientId) => action(SEARCH_PATIENT_CONDITIONS.REQUEST, {patientId}),
    success: (patientId, conditions) => action(SEARCH_PATIENT_CONDITIONS.SUCCESS, {patientId, conditions}),
    failure: (patientId, error) => action(SEARCH_PATIENT_CONDITIONS.FAILURE, {patientId, error}),
}
