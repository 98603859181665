import { all, fork } from 'redux-saga/effects';
import { watchSearchPatients, watchLoadMorePatients } from './patients';
import { watchSearchPatientConditions } from './conditions';

export default function* root() {
    yield all([
      fork(watchSearchPatients),
      fork(watchLoadMorePatients),
      fork(watchSearchPatientConditions),
    ])
  }