
export function action(type, payload = {}) {
	return {type, ...payload}
}

export function createRequestTypes(base) {
  return ['REQUEST', 'SUCCESS', 'FAILURE'].reduce((acc, type) => {
		acc[type] = `${base}_${type}`
		return acc
	}, {})
}