import React, {Component} from 'react';
import Loader from '../Loader';
import ErrorMsg from '../ErrorMsg';

export default class AsyncDisplays extends Component {
  render(){
    const {isLoading, error} = this.props;

    return isLoading ? <Loader /> :
              error ? <ErrorMsg error={error} /> : this.props.children;
  }
}
