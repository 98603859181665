import React, { Component } from 'react';
import Layout from 'antd/lib/layout';
const { Header, Content } = Layout;

class MainLayout extends Component {
  render() {
    return (
      <Layout className="layout">
        <Header>
            <h1>Patient Portal</h1>
        </Header>
        <Content className="content">
            <div className="inner">
                {this.props.children}
            </div>
        </Content>
      </Layout>
        
    );
  }
}

export default MainLayout;
