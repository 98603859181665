import 'antd/lib/layout/style/css';

import React, { Component } from 'react';
import PatientSearchPage from './pages/PatientSearchPage';
import MainLayout from './components/MainLayout';

class App extends Component {
  render() {
    return (
      <MainLayout>
        <PatientSearchPage />
      </MainLayout>
    );
  }
}

export default App;
