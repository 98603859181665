import { put, takeEvery, call } from 'redux-saga/effects';
import * as patientConditionsActions from '../actions/conditions';
import { Condition } from '../../services/api/index';

function* search({patientId}){
    try {
        yield put(patientConditionsActions.search.request(patientId));
        const patientConditions = yield call(Condition.findForPatient, patientId);
        yield put(patientConditionsActions.search.success(patientId, patientConditions));
    } catch(error){
        yield put(patientConditionsActions.search.failure(error, patientId));
    }
}

export function* watchSearchPatientConditions(){
    yield takeEvery(patientConditionsActions.SEARCH_PATIENT_CONDITIONS_CLICKED, search)
}
