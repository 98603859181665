import React, {Component} from 'react';
import propTypes from 'prop-types';
import { Column, Table, SortDirection } from 'react-virtualized';

export default class ConditionsList extends Component {
    
    static propTypes = {
        conditions: propTypes.array,
    }

    static defaultProps = {
        conditions: [],
    }

    constructor(props){
        super(props);

        this.state = {
            conditions: this.props.conditions,
            sortBy: 'dateRecorded', 
            sortDirection: SortDirection.ASC,
        }

        this.onSort = this.onSort.bind(this);
    }

    onSort({sortBy, sortDirection}) {
        const {conditions} = this.props;
        const sort = (a, b) => {
            const asc = a[sortBy] > b[sortBy] ? 1 : -1;
            return sortDirection === SortDirection.ASC ? asc : asc * -1;
          }
        const filtered = [...conditions].sort(sort)
        this.setState({conditions: filtered, sortBy, sortDirection})
    }

    conditionCellRenderer({cellData}){
        return <a href={`https://www.ncbi.nlm.nih.gov/pubmed/?term=${cellData}`} target="_blank">{cellData}</a>
    }

    dateCellRenderer({cellData}){
        return cellData || 'Not recorded'
    }
    
    render(){
        const { conditions, sortBy, sortDirection } = this.state;
        const rowHeight = 30;
        const tableHeight = rowHeight * conditions.length + rowHeight;

        return (
            <Table
                width={600}
                height={tableHeight}
                headerHeight={rowHeight}
                rowHeight={rowHeight}
                rowCount={conditions.length}
                rowGetter={({ index }) => conditions[index]}
                overscanRowCount={10}
                sort={this.onSort}
                sortBy={sortBy}
                sortDirection={sortDirection}
                >
                <Column
                    label="Date Of Visit"
                    dataKey="dateRecorded"
                    width={250}
                    cellRenderer={this.dateCellRenderer}
                />
                <Column
                    label="Condition"
                    dataKey="description"
                    width={400}
                    cellRenderer={this.conditionCellRenderer}
                />
            </Table> 
        );
    }
}