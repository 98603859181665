import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import { Collection } from '../../services/api';

import * as patientActions from '../actions/patients';
import * as patientConditionsActions from '../actions/conditions';

const initialState = {
    isLoadingPatients: false,
    isLoadingMorePatients: false,
    collection: new Collection(),
    error: null,
};

export const patientsReducer = (state = initialState, action) => {
    switch (action.type) {
        case patientActions.SEARCH_PATIENTS.REQUEST:
            return assign({}, state, {
                isLoadingPatients: true,
                error: null,
            });

        case patientActions.SEARCH_PATIENTS.SUCCESS:
            return assign({}, state, {
                isLoadingPatients: false,
                collection: action.patients,
                error: null
            });

        case patientActions.SEARCH_PATIENTS.FAILURE:
            return assign({}, state, {
                isLoadingPatients: false,
                error: "Something went wrong"
            });

        case patientConditionsActions.SEARCH_PATIENT_CONDITIONS:
            const {patientId, conditions} = action;
            const newState = cloneDeep(state);
            const patient = find(newState.collection, {id: patientId});
            patient.conditions = conditions;

            return state;


        case patientActions.LOAD_MORE_PATIENTS.REQUEST:
            return assign({}, state, {
                isLoadingMorePatients: true,
                error: null,
            });
        
        case patientActions.LOAD_MORE_PATIENTS.SUCCESS:
            const { newCollection } = action;
            newCollection.entries = [...state.collection.entries, ...newCollection.entries];
            return assign({}, state, {
                isLoadingMorePatients: false,
                collection: newCollection,
                error: null
            });
        
        case patientActions.LOAD_MORE_PATIENTS.FAILURE:
            return assign({}, state, {
                isLoadingMorePatients: false,
                error: "Something went wrong"
            });

        default:
            return state;
    }
}

export default patientsReducer