import React, {Component} from 'react';
import propTypes from 'prop-types';
import noop from 'lodash/noop';
import { Column, Table, AutoSizer } from 'react-virtualized';

export default class PatientsList extends Component {

    static propTypes = {
        patients: propTypes.array,
        onRowClick: propTypes.func,
        onLoadMore: propTypes.func,
    }

    static defaultProps = {
        patients: [],
        onRowClick: noop,
        onLoadMore: noop,
    }

    onScroll({ clientHeight, scrollHeight, scrollTop }){
        if(clientHeight + scrollTop === scrollHeight) {
            this.props.onLoadMore();
        }
    }

    render(){
        const {patients, onRowClick} = this.props;

        return (
            <div className="PatientList">
            
                <AutoSizer>
                    {({width, height}) => (
                    <Table
                        width={width}
                        height={height}
                        headerHeight={20}
                        rowHeight={30}
                        rowClassName="row"
                        rowCount={patients.length}
                        rowGetter={({ index }) => patients[index]}
                        overscanRowCount={10}
                        onRowClick={onRowClick}
                        onScroll={this.onScroll.bind(this)}
                    >
                        <Column
                            label="Id"
                            dataKey="id"
                            width={300}
                        />
                        <Column
                            label="Name"
                            dataKey="fullName"
                            width={300}
                        />
                        <Column
                        label="Gender"
                        dataKey="gender"
                        width={300}
                        />
                        <Column
                        label="Date Of Birth"
                        dataKey="dateOfBirth"
                        width={300}
                        />
                    </Table>
                    )}
                </AutoSizer>
            </div>
        )
    }
}