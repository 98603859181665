import { connect } from 'react-redux';
import pick from 'lodash/pick';
import PatientInfo from '../../components/PatientInfo';

const mapStateToProps = state => {
    return pick(state, ['patients', 'patientConditions']);
}

export default connect(
  mapStateToProps,
)(PatientInfo)