import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import noop from 'lodash/noop';
import Drawer from 'antd/lib/drawer';
import {Section} from '../../components/Layout';
import AsyncDisplays from '../../components/AsyncDisplays';
import PatientsList from '../../components/PatientsList';
import PatientInfo from '../../containers/PatientInfo';
import PatientSearch from '../../containers/PatientSearch';
import Loader from '../../components/Loader';
import { searchPatientConditions } from '../../redux/actions/conditions';
import { loadMorePatients } from '../../redux/actions/patients';


export class PatientSearchPage extends Component {

  static propTypes = {
    searchPatientConditions: propTypes.func
  }

  static defaultProps = {
    searchPatientConditions: noop
  }

  constructor(props){
    super(props);

    this.state = {
      patientInFocus: null,
      patientInfoVisible: false,
    }

    this.showPatientInfo = this.showPatientInfo.bind(this);
    this.togglePatientInFocus = this.togglePatientInFocus.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  showPatientInfo({rowData}){
    const { id } = rowData;
    this.togglePatientInFocus(true);
    this.setState({patientInFocus: id})

    if (!this.props.conditions[id]) {
      this.props.searchPatientConditions(id);
    }
  }

  togglePatientInFocus(show){
    this.setState({patientInfoVisible: show});
  }

  onSearch(){
    this.setState({patientInFocus: null});
    this.togglePatientInFocus(false);
  }

  onLoadMore(){
      this.props.loadMorePatients(this.props.patients);
  }

  render() {
    const { patients, isLoading, error } = this.props;
    const patientsCollection = patients.entries;

    return (
      <div className="PatientSearchPage flex-wraper">
        <Section>
          <h2>Search Patients</h2>
          <PatientSearch onSearch={this.onSearch} />
        </Section>

        <Section className="flex-wraper patients-section">
          <AsyncDisplays 
              isLoading={isLoading.patients} 
              error={error.patients} 
            >
            { patientsCollection && patientsCollection.length ? (
                <Fragment>
                  <PatientsList 
                    patients={patients.entries} 
                    onRowClick={this.showPatientInfo} 
                    onLoadMore={this.onLoadMore}
                  />
                </Fragment>
            ) : (
              <div className="nothing-found">
                No patients to display. Try a new search.
              </div>
            ) }
          </AsyncDisplays>
        </Section>

        {isLoading.morePatients ? <Loader text="Loading patients..." /> : null}

        <Drawer
          width={640}
          placement="right"
          closable={true}
          onClose={this.togglePatientInFocus.bind(this, false)}
          visible={this.state.patientInfoVisible}
          mask={false}
        >
          <AsyncDisplays 
            isLoading={isLoading.conditions} 
            error={error.conditions}
          >
            <PatientInfo 
              patientId={this.state.patientInFocus} 
            />
          </AsyncDisplays>
        </Drawer>
        
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {patients, patientConditions} = state;

  return {
    patients: patients.collection,
    conditions: patientConditions.collection,
    isLoading: {
      patients: patients.isLoadingPatients,
      morePatients: patients.isLoadingMorePatients,
      patientConditions: patientConditions.isLoading
    }, 
    error: {
      patients: patients.error,
      patientConditions: patientConditions.error
    }};
}

export default connect(
  mapStateToProps,
  { searchPatientConditions, loadMorePatients }
)(PatientSearchPage)

