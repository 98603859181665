import find from 'lodash/find';
import client from './client';

export default class Collection {

    constructor(response = {}, Model) {
        this.Model = Model;
        this.entries = response.entry ? response.entry.map(entry => new Model(entry)) : [];
        const next = response.link ? find(response.link, {relation: "next"}) : undefined;
        this.nextUrl = next && next.url;
    }

    get hasNext(){
        return !!this.nextUrl;
    }

    async next(){
        const {nextUrl, Model} = this;
        const response = await client({url: nextUrl})
        const newCollection = new Collection(response.data, Model)
        return newCollection;
    }
}