import {action, createRequestTypes} from './createAction';


export const SEARCH_PATIENTS_CLICKED = 'SEARCH_PATIENTS_CLICKED';
export const searchPatients = params => action(SEARCH_PATIENTS_CLICKED, {params})

export const SEARCH_PATIENTS = createRequestTypes('SEARCH_PATIENTS');
export const search = {
    request: (params) => action(SEARCH_PATIENTS.REQUEST, {params}),
    success: (params, patients) => action(SEARCH_PATIENTS.SUCCESS, {params, patients}),
    failure: (params, error) => action(SEARCH_PATIENTS.FAILURE, {params, error}),
}

export const LOAD_MORE_PATIENTS_CLICKED = 'LOAD_MORE_PATIENTS_CLICKED';
export const loadMorePatients = collection => action(LOAD_MORE_PATIENTS_CLICKED, {collection})

export const LOAD_MORE_PATIENTS = createRequestTypes('LOAD_MORE_PATIENTS');
export const loadMore = {
    request: (collection) => action(LOAD_MORE_PATIENTS.REQUEST, {collection}),
    success: (collection, newCollection) => action(LOAD_MORE_PATIENTS.SUCCESS, {collection, newCollection}),
    failure: (collection, error) => action(LOAD_MORE_PATIENTS.FAILURE, {collection, error}),
}